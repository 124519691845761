<template>
  <v-dialog v-model="dialog" width="670">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!tokenPaymentsPayed"
        small
        :style="
          !$store.getters['auth/isLoggedIn']
            ? 'margin-left: 0px !important'
            : ''
        "
        @click="fetchAppointmentTokenPayments()"
        class="mr-4"
        icon
        v-on="on"
      >
        <v-icon small>$eye</v-icon>
      </v-btn>
      <v-btn
        v-else-if="tokenPaymentsPayed /*&& tokenPayments.length*/"
        color="success"
        icon
        v-on="on"
        small
        @click="fetchAppointmentTokenPayments()"
      >
        <v-icon small>mdi-check</v-icon>
      </v-btn>
    </template>
    <v-card id="cardList">
      <v-card-title>
        {{ $tc("designs.token_payment", 2) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-simple-table>
        <thead class="v-data-table-header">
          <tr>
            <th><span>Concepto</span></th>
            <th><span>Precio</span></th>
            <th><span>Pagado</span></th>
            <th><span>Acciones</span></th>

            <th
              v-if="
                appointment.state !== 'done' &&
                  $store.getters['auth/isLoggedIn']
              "
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in tokenPayments" :key="t.id">
            <td>{{ t.concepto }}</td>
            <td>
              {{ $n(t.price, "currency") }}
            </td>
            <td>
              <!--COMPROVAR PAGADO (TIC) O NO (BTN PAGAR)-->
              <v-tooltip bottom v-if="t.payed">
                ><!--MIRAR VARIABLE PAYED DE d-->
                <template v-slot:activator="{ on }">
                  <v-btn small icon v-on="on" class="mr-2">
                    <v-icon small color="success">mdi-check</v-icon>
                  </v-btn>
                </template>
                {{ $t("payed") }}
              </v-tooltip>

              <PayAppointmentTokenPayment
                v-else
                :appointmentTokenPayment="t"
                :appointment="appointment"
                @update="fetchAppointmentTokenPayments(), $emit('update')"
              />
              <v-tooltip bottom v-if="t.payed">
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click="despagar(t)">
                    <v-icon>mdi-currency-usd-off </v-icon>
                  </v-btn>
                </template>
                {{ $t("undo", { name: "" }) }}
              </v-tooltip>
            </td>
            <td
              v-if="
                appointment.state !== 'done' &&
                  $store.getters['auth/isLoggedIn']
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-if="t.payed" icon small disabled>
                    <v-icon small>$edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    icon
                    small
                    v-on="on"
                    @click="$refs.form.editTokenPayment(t)"
                  >
                    <v-icon small>$edit</v-icon>
                  </v-btn>
                </template>
                {{ $t("edit", { name: "" }) }}
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-if="t.payed" icon small disabled>
                    <v-icon small>$delete</v-icon>
                  </v-btn>
                  <v-btn v-else icon small v-on="on" @click="del(t)">
                    <v-icon small>$delete</v-icon>
                  </v-btn>
                </template>
                {{ $t("delete", { name: "" }) }}
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-text>
        <v-row
          v-if="
            appointment.state !== 'done' && $store.getters['auth/isLoggedIn']
          "
        >
          <v-spacer></v-spacer>

          <design-dialog-token
            ref="form"
            class="mr-4"
            :appointment="appointment"
            disbled
            :sessions="appointmentDates"
            @update="
              $emit('update');
              fetchAppointmentTokenPayments();
            "
            :large="true"
          ></design-dialog-token>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
      <v-card-actions></v-card-actions>
      <v-dialog v-model="dialog_box" width="500">
        <v-card>
          <v-card-title> {{ $t("boxes.reservation") }}</v-card-title>
          <div class="close">
            <v-btn icon @click="dialog_box = false" small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <form
            action
            data-vv-scope="reserva-form"
            @submit.prevent="validateFormReserva('reserva-form')"
          >
            <v-card-text>
              <v-row
                ><v-col cols="6" class="pl-6"
                  ><label v-text="$t('boxes.tattooer')" />
                  <v-select
                    :items="tattooers"
                    v-model="res.tattooer"
                    single-line
                    item-value="id"
                    data-vv-name="tattooer"
                    dense
                    disabled
                    outlined
                    hide-details
                    data-vv-validate-on="blur"
                    :error-messages="errors.collect('reserva-form.tattooer')"
                    v-validate="'required'"
                    style="display: inline-block; width: 100%"
                    ><template slot="selection" slot-scope="data">
                      {{ data.item.user.first_name }}
                      {{ data.item.user.last_name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.user.first_name }}
                      {{ data.item.user.last_name }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6" class="pr-6">
                  <label v-text="$t('box')" />
                  <v-select
                    :items="boxes"
                    v-model="res.id"
                    single-line
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    data-vv-name="boxes"
                    :error-messages="errors.collect('reserva-form.boxes')"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    style="display: inline-block; width: 100%"
                    ><template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="
                          !data.item.boxes_tattooer_count ||
                            data.item.boxes_tattooer_count == 0
                        "
                        color="success"
                        class="mr-2"
                        small
                      >
                        mdi-check</v-icon
                      >
                      <v-icon v-else color="red" class="mr-2" small>
                        mdi-close</v-icon
                      >
                      {{ data.item.name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row style="width: 100%; margin: 0 auto">
                <v-col
                  id="time"
                  cols="12"
                  md="3"
                  lg="5"
                  class="py-md-3 pb-0 pt-0 mt-n5"
                >
                  <label v-text="$t('events.date')" />
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    transition="scale-transition"
                    disabled
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        hide-details
                        disabled
                        dense
                        v-validate="'required'"
                        data-vv-name="startDate"
                        :error-messages="
                          errors.collect('reserva-form.startDate')
                        "
                        class="filters"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="
                          res.date ? $d(new Date(res.date), 'date2digits') : ''
                        "
                        ><template v-slot:prepend-inner>
                          <!--Aqui va el v-icon -->
                          <v-icon
                            small
                            class="pt-0 pb-1"
                            style="margin-top: 5px"
                            :color="edit == false ? 'gris1' : 'primary'"
                            >$appointments</v-icon
                          >
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="res.date"
                      no-title
                      scrollable
                      color="primary"
                      :min="date"
                      @input="menu2 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="5"
                  sm="3"
                  md="3"
                  lg="3"
                  class="py-md-3 pb-0 pt-0 mt-n5"
                >
                  <label v-text="$t('events.startTime')" />
                  <v-select
                    :items="hours"
                    v-model="res.start"
                    dense
                    outlined
                    hide-details
                    disabled
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    v-bind:class="{ 'error--text': timeError }"
                    data-vv-name="startTime"
                    :error-messages="errors.collect('reserva-form.startTime')"
                  ></v-select>
                </v-col>

                <p
                  class="pa-1 mt-8 mb-0"
                  style="display: inline-block; text-align: center"
                >
                  -
                </p>
                <v-col
                  cols="5"
                  sm="3"
                  md="3"
                  lg="3"
                  class="py-md-3 pb-0 pt-0 mt-n5"
                >
                  <label v-text="$t('events.endTime')" />

                  <v-select
                    :items="hours"
                    v-model="res.end"
                    hide-details
                    v-bind:class="{ 'error--text': timeError }"
                    dense
                    disabled
                    data-vv-validate-on="blur"
                    :v-validate="res.associate ? '' : 'required'"
                    :error-messages="errors.collect('reserva-form.endTime')"
                    data-vv-name="endTime"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click="dialog = false"
                class="mr-3"
                style="height: 30px; width: 100px"
                elevation="0"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                type="submit"
                elevation="0"
                style="height: 30px; width: 100px !important"
                ><v-icon small style="margin-right: 5px">$save</v-icon
                >{{ $t("save", { name: "" }) }}</v-btn
              >
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>

      <v-dialog width="400" v-model="dialogConsentForm">
        <v-card id="consentFormLink">
          <v-card-title
            style="text-decoration: underline; text-transform: uppercase"
          >
            Formulario de consentimiento:
          </v-card-title>
          <div class="close">
            <v-btn icon @click="dialogConsentForm = false">
              <v-icon id="iconClose">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pb-2">
            <v-text-field
              outlined
              dense
              id="URL"
              hide-details
              readonly
              v-model="URL"
            >
              <template slot="prepend-inner">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mr-2" v-on="on" @click="copy()">
                      mdi-content-copy
                    </v-icon>
                  </template>
                  {{ $t("copy") }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer />
            <v-btn
              height="30px"
              elevation="0"
              color="primary"
              @click="
                $router.push({
                  name: 'ConsentFormViewIncription',
                  params: {
                    id: $route.params.id,
                    customer_id: selected.customer_id,
                    date_id: selected.date_id,
                  },
                })
              "
            >
              {{ $t("to_complete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "TokenPaymentList",

  props: ["appointmentDates", "appointment", "disabled", "customers"],
  data() {
    return {
      today: new Date(),
      dialog: false,
      dialogEdit: false,
      dialog_box: false,
      editSession: {},
      date: null,
      res: {},
      menu2: false,
      tattooers: [],
      boxes: [],
      timeStudio: [],
      timeVal: false,
      dialogConsentForm: false,
      selected: { customer_id: null, date_id: null },
      tokenPayments: [],
    };
  },
  components: {
    AddSession: () => import("@/components/appointments/sessions/Add"),
    edit: () => import("@/components/appointments/sessions/Edit"),
    PaymentTypes: () =>
      import("@/components/appointments/sessions/PaymentTypes"),
    PayAppointmentTokenPayment: () =>
      import("@/components/appointments/sessions/PayAppointmentTokenPayment"),
    DesignDialogToken: () =>
      import("@/components/appointments/design/DialogTokenPayment"),
  },
  mounted() {
    this.today = new Date();
    console.log("MONTED");
    this.fetchAppointmentTokenPayments();
  },
  computed: {
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
      }
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;
      }
    },
    hours() {
      if (this.timeStudio.length > 0) {
        let mins = [":00", ":15", ":30", ":45"];
        let hours = [];
        let first = parseInt(this.timeFrom.split(":")[0]);
        let last = parseInt(this.timeTo.split(":")[0]);
        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            if (x < 10) hours.push("0" + x + y);
            else hours.push(x + y);
          });
        }
        hours.push(last + ":00");
        if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
        return hours;
      }
    },
    URL() {
      let url = `${window.location.origin}/?#/inscription/appointment/${this.appointment.id}/consent/${this.selected.customer_id}/${this.selected.date_id}`;
      return url;
    },
    timeError() {
      if (this.res.start != undefined && this.res.end != undefined) {
        let s = this.res.start.split(":");
        let e = this.res.end.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] > e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },

    tokenPaymentsPayed() {
      let allPayed = true;
      this.tokenPayments.forEach((tokenPayment) => {
        if (!tokenPayment.payed) {
          allPayed = false;
        }
      });
      return allPayed;
    },
  },

  methods: {
    ...mapActions("appointments", [
      "getAppoinmentsTokenPayments",
      "deleteExpenseTokenPayment",
    ]),
    open() {
      this.dialog = true;
      this.fetchAppointmentTokenPayments();
    },

    despagar(appointmentTokenPayment) {
      console.log("PAGADODESPAGADO", appointmentTokenPayment);
      console.log("ID", appointmentTokenPayment.id);
      if (appointmentTokenPayment.expense.pay_mode == "online") {
        console.log("ONLINE");
        this.$confirm(this.$t("despgar_online"), "", "");
      } else {
        this.$confirm(this.$t("despagar_paga_señal"), "", "").then(() => {
          this.deleteExpenseTokenPayment({
            appointment_payment_token_id: appointmentTokenPayment.id,
          }).then(() => {
            this.$emit("update");
            this.fetchAppointmentTokenPayments();
            this.$alert(this.$t("confirm_delete_appointment_token_payment"));
          });
        });
      }
    },

    //FETCH appointmentstokenpayments
    fetchAppointmentTokenPayments() {
      console.log("fetchAppointmentTokenPayments");
      console.log(this.appointment);
      console.log(this.appointment.id);
      this.getAppoinmentsTokenPayments(this.appointment.id).then((data) => {
        this.tokenPayments = data;
        console.log(this.tokenPayments);
      });
    },

    datePassed(date) {
      date = new Date(date.substring(0, 10));
      const yesterday = new Date(this.today);
      yesterday.setDate(yesterday.getDate() - 1);
      return date.getTime() < yesterday.getTime();
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.dialogConsentForm = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    ...mapActions("appointments", ["deleteAppointmentPaymentToken"]),

    del(appointmentTokenPayment) {
      console.log("DELETE", appointmentTokenPayment);
      this.$confirm(this.$t("delete_event_question"), "", "").then(() => {
        this.deleteAppointmentPaymentToken({
          appointment_id: appointmentTokenPayment.appointment_id,
          appointment_payment_token_id: appointmentTokenPayment.id,
        }).then(() => {
          this.$emit("update");
          this.fetchAppointmentTokenPayments();
          this.$alert(this.$t("confirm_delete_appointment_token_payment"));
        });
      });
    },
    validateFormReserva(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        let timeVal = true;
        if (this.res.start > this.res.end) {
          timeVal = false;
        }

        if (result && timeVal) {
          this.reservation();
        }
      });
    },
  },
};
</script>
<style lang="sass">
#cardList
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
    background: unset
  th
    font-size: 14px !important
  td:first-child
    min-width: 170px
    max-width: 170px
  td
    border-bottom: thin solid rgba(255, 255, 255, 0.12)
    &:nth-child(2)
      min-width: 200px
      max-width: 200px
    p
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    &:nth-child(3)
      min-width: 100px
      max-width: 100px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    &:nth-child(4)

      padding-right: 0px
      padding-left: 0px

    &:nth-child(5)
      min-width: 65px
      max-width: 65px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    &:nth-child(6)
      margin-right: 40px !important
      padding-left: 0px

      width: 20px
      max-width: 20px
      white-space: nowrap

      overflow: hidden
  th:nth-child(4)
    min-width: 80px
    max-width: 80px
  th:nth-child(6)

    width: 90px
    max-width: 90px
@media (max-width: 430px)
  #cardList

    td:nth-child(1)

      min-width: 150px
      max-width: 150px

    td:nth-child(6)
      margin-right: 40px !important
      padding-left: 0px

      width: 90px
      max-width: 90px
      white-space: nowrap

      overflow: hidden
    td:nth-child(4)

      min-width: 90px
      max-width: 90px
      white-space: nowrap

      overflow: hidden
    td:nth-child(5)
      min-width: 90px
      max-width: 90px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
    th:nth-child(1)

      min-width: 70px
      padding-left: 5px
      padding-right: 5px
    th:nth-child(3)

      min-width: 80px
      padding-left: 5px
      padding-right: 5px
    th:nth-child(4)

      min-width: 80px
      padding-left: 5px
      padding-right: 5px
    th:nth-child(5)

      min-width: 50px
      padding-left: 5px
      padding-right: 5px
    th:nth-child(6)

      min-width: 00px
</style>
